@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@-webkit-keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
}
@keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
}

.pro-sidebar {
    color: #adadad;
    height: 100%;
    width: 270px;
    min-width: 270px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
}

.pro-sidebar>.pro-sidebar-inner {
    background: #1d1d1d;
    height: 100%;
    position: relative;
    z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.3;
    left: 0;
    top: 0;
    z-index: 100;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: none;
}

.pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

.pro-sidebar.rtl {
    text-align: right;
    direction: rtl;
}

@media (max-width: 480px) {
    .pro-sidebar.xs {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.xs.collapsed {
        left: -80px;
    }
    .pro-sidebar.xs.toggled {
        left: 0;
    }
    .pro-sidebar.xs.toggled .overlay {
        display: block;
    }
    .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.xs.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.xs.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 576px) {
    .pro-sidebar.sm {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.sm.collapsed {
        left: -80px;
    }
    .pro-sidebar.sm.toggled {
        left: 0;
    }
    .pro-sidebar.sm.toggled .overlay {
        display: block;
    }
    .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.sm.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.sm.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 768px) {
    .pro-sidebar.md {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.md.collapsed {
        left: -80px;
    }
    .pro-sidebar.md.toggled {
        left: 0;
    }
    .pro-sidebar.md.toggled .overlay {
        display: block;
    }
    .pro-sidebar.md.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.md.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.md.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 992px) {
    .pro-sidebar.lg {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.lg.collapsed {
        left: -80px;
    }
    .pro-sidebar.lg.toggled {
        left: 0;
    }
    .pro-sidebar.lg.toggled .overlay {
        display: block;
    }
    .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.lg.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.lg.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 1200px) {
    .pro-sidebar.xl {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.xl.collapsed {
        left: -80px;
    }
    .pro-sidebar.xl.toggled {
        left: 0;
    }
    .pro-sidebar.xl.toggled .overlay {
        display: block;
    }
    .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.xl.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.xl.rtl.toggled {
        left: auto;
        right: 0;
    }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: '';
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar .pro-menu {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #adadad;
}

.pro-sidebar .pro-menu a:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
}

.pro-sidebar .pro-menu a:hover {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
    display: -webkit-flex;
    display: flex;
    margin-right: 5px;
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 35px 8px 20px;
    cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
    outline: none;
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 10px;
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item:before {
    background: #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    display: inline-block;
    border-style: solid;
    border-color: #adadad;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item:before {
    background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 20px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
    display: none;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    background-color: rgba(255, 255, 255, 0.05);
}

.pro-sidebar .pro-menu.square .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 0;
}

.pro-sidebar .pro-menu.round .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 4px;
}

.pro-sidebar .pro-menu.circle .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 50%;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.suffix-wrapper,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.prefix-wrapper {
    opacity: 0;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-list-item {
    background-color: rgba(29, 29, 29, 0.95);
    z-index: 111;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item {
    pointer-events: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    display: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    height: auto !important;
    position: fixed;
    visibility: hidden;
    min-width: 220px;
    max-width: 270px;
    background-color: transparent;
    max-height: 100%;
    padding-left: 3px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-left: 10px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: rgba(29, 29, 29, 0.95);
    padding-left: 20px;
    border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
    transition: visibility, -webkit-transform 0.3s;
    transition: visibility, transform 0.3s;
    transition: visibility, transform 0.3s, -webkit-transform 0.3s;
    visibility: visible;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
    margin-right: 0;
    margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 8px 20px 8px 35px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    right: auto;
    left: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    margin-right: 0;
    margin-left: 15px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    padding-left: 0;
    padding-right: 3px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-right: 10px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 5px 8px 30px;
}

.popper-arrow {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
    right: 0;
    border-right: 7px solid rgba(29, 29, 29, 0.95);
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
    left: 0;
    border-left: 7px solid rgba(29, 29, 29, 0.95);
}

.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}

body {
    margin: 0;
    height: 100vh;
    color: #353535;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
}

.app .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 15px;
    display: none;
}

.app .sidebar-btn-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.app .sidebar-btn-wrapper .sidebar-btn {
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.app .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 5px;
    font-size: 13px;
}

.app .sidebar-btn-wrapper .sidebar-btn:hover {
    color: #d8d8d8;
}

.app .collapsed .sidebar-btn {
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 40px;
    padding: 0;
}

.app main {
    padding: 24px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
}

.app main header {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
}

.app main header h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
}

.app main footer {
    margin-top: auto;
    color: #888;
    text-align: center;
}

.app main footer a {
    text-decoration: none;
    color: #888;
}

.app main footer a:hover {
    text-decoration: underline;
}

.app .social-bagdes {
    margin-top: 10px;
}

.app .social-bagdes img {
    margin: 5px;
}

.app .block {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
}

.app .block>span {
    margin-left: 10px;
}

.app.rtl {
    direction: rtl;
    text-align: right;
}

.app.rtl header h1 {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
}

.app.rtl .block {
    direction: rtl;
}

.app.rtl .block>span {
    margin-left: 0;
    margin-right: 10px;
}

.app.rtl .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 0;
    margin-right: 5px;
}

@media (max-width: 767.98px) {
    .app {
        overflow-x: hidden;
    }
    .app header h1 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .app .btn-toggle {
        display: -webkit-flex;
        display: flex;
    }
    .app.rtl .btn-toggle {
        margin-left: auto;
    }
}

.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
}

.badge.red {
    color: #ffffff;
    background: #d63030;
}

.badge.gray {
    color: #ffffff;
    background: #777777;
}

.badge.yellow {
    color: #000000;
    background: #ffd331;
}

.app-wrapper {
    padding: 0;
}

.side-navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    z-index: 1000;
    height: 100vh;
    background-color: #f7f7f7;
    border-right: 1px solid #ececec;
}

.page-wrapper {
    padding: 0;
    min-height: 100vh;
    width: 100vw;
}

.dynamic-content {
    padding: 2rem 4rem;
    overflow: scroll;
}

.product-image {
    height: 5rem;
    width: 5rem;
}

.button-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.button-actions.img {
    min-height: 7rem;
}

.button-actions.img button {
    margin: auto;
}

.button-actions button:first-child {
    margin-right: 9px;
}

.top-header {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-left: 1rem;
    width: 83%;
}

.top-header button {
    height: -webkit-max-content;
    height: max-content;
}

.nav-tabs {
    width: 100vw !important;
    margin-bottom: 1rem !important;
}

.tab-content {
    margin-left: 1rem;
}

.tab-new-button {
    margin-bottom: 1rem !important;
    min-width: 20rem;
}

.input-tag input {
    border: 1px solid #ced4da;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    width: 100%;
}

.input-tag__tags {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
}

.input-tag__tags li {
    -webkit-align-items: center;
            align-items: center;
    background: #85A3BF;
    border-radius: 2px;
    color: white;
    display: -webkit-flex;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.input-tag__tags li button {
    -webkit-align-items: center;
            align-items: center;
    -webkit-appearance: none;
            appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    -webkit-justify-content: center;
            justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
    background: none;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 0;
}
@-webkit-keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
}
@keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    10% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }
    40% {
        -webkit-transform: rotate(-10deg);
                transform: rotate(-10deg);
    }
    60% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
    80% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }
}

.pro-sidebar {
    color: #adadad;
    height: 100%;
    width: 270px;
    min-width: 270px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
}

.pro-sidebar>.pro-sidebar-inner {
    background: #1d1d1d;
    height: 100%;
    position: relative;
    z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>img.sidebar-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.3;
    left: 0;
    top: 0;
    z-index: 100;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 101;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-header {
    border-bottom: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar>.pro-sidebar-inner>.pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: none;
}

.pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

.pro-sidebar.rtl {
    text-align: right;
    direction: rtl;
}

@media (max-width: 480px) {
    .pro-sidebar.xs {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.xs.collapsed {
        left: -80px;
    }
    .pro-sidebar.xs.toggled {
        left: 0;
    }
    .pro-sidebar.xs.toggled .overlay {
        display: block;
    }
    .pro-sidebar.xs.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.xs.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.xs.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 576px) {
    .pro-sidebar.sm {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.sm.collapsed {
        left: -80px;
    }
    .pro-sidebar.sm.toggled {
        left: 0;
    }
    .pro-sidebar.sm.toggled .overlay {
        display: block;
    }
    .pro-sidebar.sm.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.sm.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.sm.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 768px) {
    .pro-sidebar.md {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.md.collapsed {
        left: -80px;
    }
    .pro-sidebar.md.toggled {
        left: 0;
    }
    .pro-sidebar.md.toggled .overlay {
        display: block;
    }
    .pro-sidebar.md.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.md.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.md.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 992px) {
    .pro-sidebar.lg {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.lg.collapsed {
        left: -80px;
    }
    .pro-sidebar.lg.toggled {
        left: 0;
    }
    .pro-sidebar.lg.toggled .overlay {
        display: block;
    }
    .pro-sidebar.lg.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.lg.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.lg.rtl.toggled {
        left: auto;
        right: 0;
    }
}

@media (max-width: 1200px) {
    .pro-sidebar.xl {
        position: fixed;
        left: -270px;
    }
    .pro-sidebar.xl.collapsed {
        left: -80px;
    }
    .pro-sidebar.xl.toggled {
        left: 0;
    }
    .pro-sidebar.xl.toggled .overlay {
        display: block;
    }
    .pro-sidebar.xl.rtl {
        left: auto;
        right: -270px;
    }
    .pro-sidebar.xl.rtl.collapsed {
        left: auto;
        right: -80px;
    }
    .pro-sidebar.xl.rtl.toggled {
        left: auto;
        right: 0;
    }
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: '';
    display: inline-block;
    width: 4px;
    min-width: 4px;
    height: 4px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    box-shadow: 1px 0px 0px #adadad, 0px -1px 0px #adadad, 0px 1px 0px #adadad, -1px 0px 0px #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pro-sidebar .pro-menu {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #adadad;
}

.pro-sidebar .pro-menu a:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
}

.pro-sidebar .pro-menu a:hover {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item .suffix-wrapper {
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item .prefix-wrapper {
    display: -webkit-flex;
    display: flex;
    margin-right: 5px;
    opacity: 1;
    transition: opacity 0.2s;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 8px 35px 8px 20px;
    cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
    outline: none;
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 10px;
    font-size: 14px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper .pro-icon {
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
    color: #d8d8d8;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item:before {
    background: #adadad;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    display: inline-block;
    border-style: solid;
    border-color: #adadad;
    border-width: 0 2px 2px 0;
    padding: 2.5px;
    vertical-align: middle;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item:before {
    background: transparent !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 20px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item .pro-arrow-wrapper {
    display: none;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    background-color: rgba(255, 255, 255, 0.05);
}

.pro-sidebar .pro-menu.square .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 0;
}

.pro-sidebar .pro-menu.round .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 4px;
}

.pro-sidebar .pro-menu.circle .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    border-radius: 50%;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.suffix-wrapper,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-item>.prefix-wrapper {
    opacity: 0;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item>.pro-inner-list-item {
    background-color: rgba(29, 29, 29, 0.95);
    z-index: 111;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu {
    position: relative;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item {
    pointer-events: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    display: none;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    height: auto !important;
    position: fixed;
    visibility: hidden;
    min-width: 220px;
    max-width: 270px;
    background-color: transparent;
    max-height: 100%;
    padding-left: 3px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-left: 10px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: rgba(29, 29, 29, 0.95);
    padding-left: 20px;
    border-radius: 4px;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover>.pro-inner-list-item {
    transition: visibility, -webkit-transform 0.3s;
    transition: visibility, transform 0.3s;
    transition: visibility, transform 0.3s, -webkit-transform 0.3s;
    visibility: visible;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 30px 8px 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item .prefix-wrapper {
    margin-right: 0;
    margin-left: 5px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 8px 20px 8px 35px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 0;
    margin-left: 10px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
    right: auto;
    left: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu.open>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 15px 8px 30px;
}

.pro-sidebar.rtl .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    margin-right: 0;
    margin-left: 15px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item {
    padding-left: 0;
    padding-right: 3px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item.has-arrow {
    padding-right: 10px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    padding-left: 0;
    padding-right: 20px;
}

.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-sub-menu-item,
.pro-sidebar.rtl.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
    padding: 8px 5px 8px 30px;
}

.popper-arrow {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}

.popper-element[data-popper-placement^='left']>.popper-arrow {
    right: 0;
    border-right: 7px solid rgba(29, 29, 29, 0.95);
}

.popper-element[data-popper-placement^='right']>.popper-arrow {
    left: 0;
    border-left: 7px solid rgba(29, 29, 29, 0.95);
}

.react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
}

.react-slidedown.transitioning {
    overflow-y: hidden;
}

.react-slidedown.closed {
    display: none;
}

body {
    margin: 0;
    height: 100vh;
    color: #353535;
    font-family: 'Roboto', sans-serif;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
}

.app .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 15px;
    display: none;
}

.app .sidebar-btn-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.app .sidebar-btn-wrapper .sidebar-btn {
    padding: 1px 15px;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.app .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 5px;
    font-size: 13px;
}

.app .sidebar-btn-wrapper .sidebar-btn:hover {
    color: #d8d8d8;
}

.app .collapsed .sidebar-btn {
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 40px;
    padding: 0;
}

.app main {
    padding: 24px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto;
}

.app main header {
    margin-bottom: 40px;
    border-bottom: 1px solid #efefef;
}

.app main header h1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
}

.app main footer {
    margin-top: auto;
    color: #888;
    text-align: center;
}

.app main footer a {
    text-decoration: none;
    color: #888;
}

.app main footer a:hover {
    text-decoration: underline;
}

.app .social-bagdes {
    margin-top: 10px;
}

.app .social-bagdes img {
    margin: 5px;
}

.app .block {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
}

.app .block>span {
    margin-left: 10px;
}

.app.rtl {
    direction: rtl;
    text-align: right;
}

.app.rtl header h1 {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
}

.app.rtl .block {
    direction: rtl;
}

.app.rtl .block>span {
    margin-left: 0;
    margin-right: 10px;
}

.app.rtl .sidebar-btn-wrapper .sidebar-btn span {
    margin-left: 0;
    margin-right: 5px;
}

@media (max-width: 767.98px) {
    .app {
        overflow-x: hidden;
    }
    .app header h1 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .app .btn-toggle {
        display: -webkit-flex;
        display: flex;
    }
    .app.rtl .btn-toggle {
        margin-left: auto;
    }
}

.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
}

.badge.red {
    color: #ffffff;
    background: #d63030;
}

.badge.gray {
    color: #ffffff;
    background: #777777;
}

.badge.yellow {
    color: #000000;
    background: #ffd331;
}

.main-container {
    height: 100vh;
    width: 100vw;
    overflow: scroll;
}
